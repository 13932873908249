import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { MdDelete } from "react-icons/md";
const $ = require("jquery");
$.Datatable = require("datatables.net");

export default function Drop() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [challenge, setChallenge] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const setpageLimit = (event) => {
    setLimit(event.target.value);
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const AllChallenge = async () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(
        `${baseUrl}challange/drop_challange?page=${pageNumber}&_limit=${limit}`,
        { headers }
      );

      // Handle empty or non-existent challenge data
      const challenges = res.data.status || [];
      setChallenge(challenges);
      setNumberOfPages(res.data.totalPages || 0);
    } catch (error) {
      console.error("Error fetching challenges", error);
      setChallenge([]); // Set to empty in case of error
    }
  };

  const CancelGame = async (id) => {
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (confirm) {
      try {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        const res = await axios.delete(
          `${baseUrl}dropedchallange/delete/${id}`,
          { headers }
        );

        if (res.data.message === "Game challenges not found") {
          setChallenge([]); // Set to empty if not found
        } else {
          AllChallenge(); // Refresh challenges
        }
      } catch (error) {
        console.error("Error deleting challenge", error);
      }
    } else {
      window.alert("Sorry, try again");
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    AllChallenge();
  }, [pageNumber, limit]);

    return (
        <>
            {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">DROP CHALLANGES</h4>
                            <select
                className="form-control col-sm-1 bg-light text-dark"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#343a40",
                  color: "#fff",
                  marginLeft: "150vh",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              {challenge.length > 0 ? (
                <>
                            <div className="table-responsive">
                                <table className="table" >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> ID</th>
                                            <th> Creator</th>
                                            <th> Accepter</th>
                                            <th> Amount </th>
                                            <th> Status </th>
                                            <th> Game Type </th>
                                            <th>Date</th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {challenge.map((game, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{game._id}</td>
                                                <td>
                                                    <span className="pl-2 ">{game.createdBy ? game.createdBy.name : null}</span>
                                                </td>
                                                
                                                <td>
                                                    <span className="pl-2">{game.acceptedBy ? game.acceptedBy.name : null}</span>
                                                </td>
                                                <td>{game.gameAmount}</td>
                                                <td className='text-primary font-weight-bold'>{game.status}</td>
                                                <td> {decodeURIComponent(game.gameType)}</td>

                                                <td>{dateFormat(game.createdAt).split(',')[0]} </td>
                                                <td>
                                                    {
                                                        (game.createdBy && game.acceptedBy)? <Link type='button' className="btn btn-primary mx-1"to={`/view/${game._id}`}>View</Link> :''
                                                    }
                                                    
                                                    {game.status == "drop" && <button type='button' className="btn  mx-1 btn-danger" onClick={() => CancelGame(game._id)}>Delete</button>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                             {numberOfPages > 1 && (
                                <div className="mt-4">
                                  <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={numberOfPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    pageLinkStyle={{ color: "#fff" }}
                                    activeLinkStyle={{
                                      backgroundColor: "#00ff00",
                                      borderColor: "#00ff00",
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: "#14213d",
                                padding: "20px",
                                borderRadius: "10px",
                                color: "#fff",
                              }}
                            >
                              <h4>No Data Found</h4>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
