import React, { useState, useEffect } from "react";

import axios from "axios";

const Gateway = () => {
  const [razorPayout, setRazorpayout] = useState(true);
  const [razorDeposit, setRazorDeposit] = useState(true);
  const [razorpayAuto, setRazorpayAuto] = useState(false);
  const [razorPayKey, setRazorpayKey] = useState();
  const [razorPaySecretKey, setRazorpaysecretKey] = useState();
  const [accountName, setAccountName] = useState();
  const [PhoneStatus, setPhoneStatus] = useState(true);
  const [PhoneMID, setPhoneMID] = useState(true);
  const [phonePeKey, setPhonePeKey] = useState(false);
  const [upiGateway, setUpiGateway] = useState(false);
  const [upiGatewayKey, setUpiGatewayKey] = useState("");
  const [settingId, setSettingId] = useState("");
  const [kvmApiKey, setKvmApiKey] = useState("");
  const [kvmSecretKey, setKvmSecretKey] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.razorPayout);
      setRazorDeposit(res.data.razorDeposit);
      setRazorpayAuto(res.data.razorpayAuto);
      setPhoneStatus(res.data.phonePeKey);
      setPhoneMID(res.data.PhoneMID);
      setPhonePeKey(res.data.phonePeKey);
      setRazorpayKey(res.data.razorPayKey);
      setRazorpaysecretKey(res.data.razorPaySecretKey);
      setAccountName(res.data.accountName);
      setUpiGateway(res.data.upiGateway);
      setUpiGatewayKey(res.data.upiGatewayKey);
      setKvmApiKey(res.data.kvmApiKey);
      setKvmSecretKey(res.data.kvmSecretKey);
    });
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const response = await axios.post(baseUrl + `gatewaysettings`, {
      settingId,
      razorPayout,
      razorDeposit,
      razorpayAuto,
      PhoneStatus,
      PhoneMID,
      phonePeKey,
      razorPayKey,
      razorPaySecretKey,
      accountName,
      upiGateway,
      upiGatewayKey,
      kvmApiKey,
      kvmSecretKey,
    });
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        enctype="multipart/form-date"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="col-2 my-1">
              Razorpay Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={razorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazdep" className="col-2 my-1">
              Razorpay Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={razorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttonrazauto" className="col-2 my-1">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={razorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key</label>
            <input
              className="form-control"
              type="text"
              value={razorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey</label>
            <input
              className="form-control"
              type="text"
              value={razorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="accountName">Account name</label>
            <input
              className="form-control"
              type="text"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">PhonePE</h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={PhoneStatus}
              onChange={(e) => setPhoneStatus(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              MID
            </label>
            <input
              className="form-control"
              type="text"
              value={PhoneMID}
              onChange={(e) => setPhoneMID(e.target.value)}
            />
            <label htmlFor="buttondecdep" className="col-2 my-1">
              KEY
            </label>
            <input
              className="form-control"
              type="text"
              value={phonePeKey}
              onChange={(e) => setPhonePeKey(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">KVM</h5>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              API Key
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter KVM API key"
              value={kvmApiKey}
              onChange={(e) => setKvmApiKey(e.target.value)}
            />
            <label htmlFor="buttondecdep" className="col-2 my-1">
              Secret Key
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter KVM Secret Key"
              value={kvmSecretKey}
              onChange={(e) => setKvmSecretKey(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">
              UPI Gateway
            </h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={upiGateway}
              onChange={(e) => setUpiGateway(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
            <label htmlFor="buttondecdep" className="col-2 my-1">
              KEY
            </label>
            <input
              className="form-control"
              type="text"
              value={upiGatewayKey}
              onChange={(e) => setUpiGatewayKey(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
