import React, { useState, useEffect } from "react";
import axios from "axios";

const Messagebox2 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [data, setData] = useState("");
  const [messagess, setMessagess] = useState([]);

  // useEffect(() => {
  //   const storedMessages = JSON.parse(localStorage.getItem("messagess"));
  //   if (storedMessages) {
  //     setMessagess(storedMessages);
  //   } else {
  //     axios
  //       .get(baseUrl + "messagess")
  //       .then((response) => {
  //         const fetchedMessages = response.data.messages;
  //         setMessagess(fetchedMessages);
  //         localStorage.setItem("messages", JSON.stringify(fetchedMessages));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, []);
  

  function submit(e) {
    e.preventDefault();
    axios
      .post(baseUrl + "message2", {
        message: data,
      })
      .then((response) => {
        const newMessage = response.data.newMessage;
        setMessagess([...messagess, newMessage]);
        setData("");

        localStorage.setItem(
          "messagess",
          JSON.stringify([...messagess, newMessage])
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onDelete = (postIdToDelete) => {
    axios
      .delete(baseUrl + `deletemessage2/${postIdToDelete}`)
      .then(() => {
        const updatedMessages = messagess.filter(
          (message) => message._id !== postIdToDelete
        );
        setMessagess(updatedMessages);

        localStorage.setItem("messagess", JSON.stringify(updatedMessages));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const clearLocalStorage = () => {
  //   localStorage.removeItem("messagess");
  //   setMessagess([]);
  // };
  
  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    
    axios.get(baseUrl + "getmessage2").then((response) => {
      setPostsecond(response.data);
    });
  }, []);

  return (
    <>
      <div className="d-column" style={{ gap: "8px" }}>
        <form onSubmit={(e) => submit(e)}>
          <div className="mb-1">
            <textarea
              className="w-100"
              style={{ border: "1px solid black" }}
              rows="3"
              id="message"
              onChange={(e) => setData(e.target.value)}
              value={data}
            ></textarea>
          </div>

          <div className="d-flex mb-2  justify-content-center">
            <button onClick={() => window.location.reload()}  type="submit" className="btn btn-success shadow">
              SEND
            </button>
          </div>
        </form>
        <div>
          <table
            className="table table-dark table-striped w-md-75"
            style={{ border: "1px solid black" }}
          >
            <thead style={{background:"#7094c9", color:"black"}}>
              <tr>
                <th>Message</th>
                <th>Action</th>
              </tr>
              {/* <div>
        <button
          onClick={clearLocalStorage}
          className="btn btn-warning shadow"
        >
          Clear Storage
        </button>
      </div> */}
            </thead>
        
           
            <tbody>
           

              {postsecond.map((ele)=>(
                <tr key={ele._id}>
                 
                <td className="text-white text-wrap">{ele.message}</td>
                <td>
                  <button
                    onClick={() =>  { onDelete(ele._id);
                      window.location.reload();
                    }}
                    className="btn btn-danger shadow"
                  >
                    DELETE
                  </button>
                </td>
              </tr>
              ))}
              
              
                    

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Messagebox2;
